<template>
  <b-container fluid>
    <b-col sm="12">
      <RolesList />
    </b-col>
  </b-container>
</template>

<script>
export default {
  components: {
    BCol,
    BContainer,
    RolesList
  },
  data() {
    return {
      form: {
        id: "",
        description: "",
        idDoCliente: ""
      }
    }
  }
}
import RolesList from "./RolesList"
import { BCol, BContainer } from "bootstrap-vue"
</script>

<style></style>
