/* eslint-disable arrow-parens */
<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card>
          <div class="custom-search d-flex justify-content-end">
            <b-col md="6">
              <b-form-group>
                <div class="d-flex align-items-center">
                  <b-form-input
                    v-model="searchQuery"
                    placeholder="Procurar..."
                    type="text"
                    class="d-inline-block"
                  />
                  <!-- <label class="ml-1 mr-1">Pesquisar</label> -->
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="ml-1"
                    variant="primary"
                    @click="addRoles()"
                  >
                    Adicionar
                  </b-button>
                </div>
              </b-form-group>
            </b-col>
          </div>

          <!-- table -->
          <b-card-actions
            ref="loadingCard"
            no-actions
          >
            <vue-good-table
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: true,
                externalQuery: searchQuery
              }"
              :select-options="{
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }"
              :pagination-options="{
                enabled: true,
                perPage: pageLength
              }"
            >
              <template
                slot="table-row"
                slot-scope="props"
              >
                <!-- Column: Name -->
                <span v-if="props.column.field === 'nomeCliente'">
                  <span>{{ props.row.nomeCliente }}</span>
                </span>
                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                  <span>
                    <a
                      v-b-tooltip.hover.bottom="'Detalhes'"
                      :href="'/detalhes-cargo/' + props.row.idDaFuncao"
                      class="icone"
                    >
                      <feather-icon icon="FileTextIcon" />
                    </a>

                    <b-dropdown
                      variant="link"
                      toggle-class="text-decoration-none"
                      no-caret
                    >
                      <template v-slot:button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="text-body align-middle mr-25"
                        />
                      </template>
                      <!-- <b-dropdown-item
                        :to="{
                          name: 'detail-roles',
                          params: { id: props.row.idDaFuncao }
                        }"
                      >
                        <feather-icon icon="FileTextIcon" />
                        <span class="align-middle ml-50">Detalhes</span>
                      </b-dropdown-item> -->

                      <b-dropdown-item
                        :to="{
                          name: 'role-edit',
                          params: { id: props.row.idDaFuncao }
                        }"
                      >
                        <feather-icon
                          icon="Edit2Icon"
                          class="mr-50"
                        />
                        <span>Editar</span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="showModal(props.row.idDaFuncao)">
                        <feather-icon icon="TrashIcon" />
                        <span class="align-middle ml-50">Excluir</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </span>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap ">
                      Mostrando 1 até
                    </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['5', '10', '30']"
                      class="mx-1"
                      @input="
                        value => props.perPageChanged({ currentPerPage: value })
                      "
                    />
                    <span class="text-nowrap">
                      de {{ props.total }} registros
                    </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      aria-controls="clientTable"
                      :total-rows="props.total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      class="mt-1 mb-0"
                      prev-class="prev-item"
                      next-class="next-item"
                      @input="
                        value => props.pageChanged({ currentPage: value })
                      "
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
              <div slot="emptystate">
                Nenhum registro encontrado!
              </div>
            </vue-good-table>
          </b-card-actions>
        </b-card>
        <!-- <DeleteModal
          :id="selectedRoleId"
          ref="modal"
          @inactivateRole="updateModal"
        /> -->
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  VBTooltip,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BFormSelect
} from "bootstrap-vue"
import BCardActions from "@core/components/b-card-actions/BCardActions.vue"
import vSelect from "vue-select"
import { onUnmounted } from "@vue/composition-api"
import store from "@/store"
import rolesStoreModule from "@/views/forms/Roles/rolesStoreModule"
import DeleteModal from "@/views/forms/Roles/modal-delete-roles.vue"
import { getUserData } from "@/auth/utils"
import Ripple from "vue-ripple-directive"
import { VueGoodTable } from "vue-good-table"
import useJwt from "@/auth/jwt/useJwt"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"

export default {
  components: {
    VueGoodTable,
    BCard,
    BCardActions,
    BRow,
    BCol,
    BFormInput,
    BButton,
    // BTable,
    // BMedia,
    // BAvatar,
    // BLink,
    // BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BFormSelect
    // vSelect,
    // DeleteModal
  },
  directives: { Ripple, "b-tooltip": VBTooltip },

  props: {
    selectedFilter: {
      type: String,
      required: false,
      default: ""
    },
    idCliente: {
      type: Number,
      required: false,
      default: 0
    }
  },
  // setup() {
  //   const USER_APP_STORE_MODULE_NAME = "app-roles"

  //   // Register module
  //   if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
  //     store.registerModule(USER_APP_STORE_MODULE_NAME, rolesStoreModule)
  //   }

  //   // UnRegister on leave
  //   onUnmounted(() => {
  //     if (store.hasModule(USER_APP_STORE_MODULE_NAME)) {
  //       store.unregisterModule(USER_APP_STORE_MODULE_NAME)
  //     }
  //   })
  // },
  data() {
    return {
      selectedRoleId: 0,
      pageLength: 5,
      idDoPerfil: 0,
      dir: false,
      columns: [
        {
          field: "nomeDaEmpresa",
          label: "NOME DA EMPRESA",
          sortable: true
        },
        {
          field: "descricaoDaFuncao",
          label: "CARGO",
          sortable: true
        },
        {
          field: "action",
          label: "Ações"
        }
      ],
      searchQuery: "",
      rows: []

      // perPage: 10,
      // currentPage: 1,
      // perPageOptions: [5, 10, 25, 50, 100]
    }
  },
  computed: {},
  mounted() {
    this.$refs["loadingCard"].showLoading = true
    this.idDoPerfil = Number(getUserData().idDoPerfil)
    this.getListaRoles()
    this.removeColumns()
  },
  methods: {
    async getListaRoles() {
      if (this.idDoPerfil === 1) {
        const resp = await useJwt
          .listRolesAll()
          .then(response => response.data)
          .catch(response => response.data)
        if (resp.sucesso) {
          // console.log("resposne Master: ", resp.dados.rows)
          this.rows = resp.dados.listaFuncao
        }
      } else {
        const resp = await useJwt
          .listRoles()
          .then(response => response.data)
          .catch(response => response.data)
        if (resp.sucesso) {
          // console.log("response Outros: ", resp.dados.rows)
          this.rows = resp.dados.listaFuncao
        }
      }
      this.$refs["loadingCard"].showLoading = false
    },

    addRoles() {
      this.$router.push({ name: "role-add" })
    },

    removeColumns() {
      if (this.idDoPerfil != 1) {
        this.columns.splice("key", 1)
      }
    },
    showModal(id) {
      this.selectedRoleId = 0
      this.$bvModal
        .msgBoxConfirm("Deseja excluir o Cargo?", {
          title: "Confirmar a exclusão",
          size: "sm",
          okVariant: "primary",
          okTitle: "SIM",
          cancelTitle: "Cancelar",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          // aqui vai o codigo para delete ou cancel
          if (value == true) {
            this.inactivateCargo(id)
          }
        })
    },
    async inactivateCargo(id) {
      const idCargo = id
      const resp = await useJwt
        .inactivateRole(idCargo)
        .then(response => response.data)
        .catch(response => response.data)

      if (resp.sucesso) {
        this.MensagemCargoExcluido("success", "top-center")
        setTimeout(() => {
          this.getListaRoles()
        }, 2000)
      } else {
        this.MensagemErrorCargo("danger", "top-center")
      }
    },
    MensagemCargoExcluido(variant, position) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Cargo Excluído!",
            icon: "EditIcon",
            variant
          }
        },
        {
          position
        }
      )
    },
    MensagemErrorCargo(variant, position) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Erro ao Excluir Cargo, Por favor verifique sua conexão!",
            icon: "ErrorIcon",
            variant
          }
        },
        {
          position
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.icone {
  color: inherit;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
