<template>
  <b-modal
    id="modal-delete"
    title="Excluir Cargo"
    centered
    cancel-title="Cancelar"
    cancel-variant="outline-secondary"
    @ok="inactivateRole()"
  >
    <b-card-text>
      Deseja Excluir esse Cargo?
    </b-card-text>
  </b-modal>
</template>

<script>
import { BModal, VBModal, BCardText } from "bootstrap-vue"
import { onUnmounted } from "@vue/composition-api"
import store from "@/store"
import userStoreModule from "./rolesStoreModule"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import useJwt from "@/auth/jwt/useJwt"

export default {
  components: {
    BCardText,
    BModal,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent
  },
  directives: {
    "b-modal": VBModal
  },
  props: {
    id: {
      Type: Number,
      required: true
    }
  },

  methods: {
    async inactivateRole() {
      const id = this.id
      const resp = await useJwt
        .inactivateRole(id)
        .then(response => response.data)
        .catch(response => response.data)

      if (resp.sucesso) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Cargo Excluído!",
            icon: "EditIcon",
            variant: "success"
          }
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao Excluir usuário, Por favor verifique sua conexão!",
            icon: "ErrorIcon",
            variant: "danger"
          }
        })
      }
    }
  }
}
</script>

<style></style>
